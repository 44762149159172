import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import Loader from "../Loader";
import { format } from "date-fns";
import { spacing } from "@material-ui/system";
import { eventStatuses } from "./dynamicsConstants";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import { grey } from "@material-ui/core/colors";
import { KeyboardDatePicker } from "@material-ui/pickers";


import {
    Typography,
    Card as MuiCard,
    CardContent,
    Grid,
    Button as MuiIconButton,
    makeStyles,
    CircularProgress,
    Box,
    FormControl,
    MenuItem,
    Select
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Replay as UndoIcon
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(4, 4),
    },
    gridItem: {
        paddingRight: theme.spacing(4),
    },
}));

const KnowledgeEventDetailsCard = ({ knowledgeState, updateEvent, ev, setModeField }) => {    

    const classes = useStyles();
    const mode = knowledgeState.modeFields.eventDetails;

    useEffect(() => {
        setModeField("eventDetails", "view");
    }, [setModeField]);

    const handleChange = async (e) => {

        let field = e.target.name;
        let value = e.target.value;

        let newEvent = { ...ev, [field]: value }
        updateEvent(newEvent, field, "eventDetails");        
    };

    const handleDateChange = async (field, date) => {
        let newEvent = { ...ev, [field]: date }
        updateEvent(newEvent, field, "eventDetails");        
    };

    const eventStartDate = ev.start ? new Date(ev.start) : null;
    
    if (!ev)
        return <Loader />;

    const eventDetails = {
        "Start Date": mode === "edit"
            ? (
                <KeyboardDatePicker
                    style={{ marginTop: "-8px" }}
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={eventStartDate}
                    onChange={(date) => handleDateChange('start', date)}
                />
            )
            : ev.start && format(eventStartDate, "dd/MM/yyyy"),        
        "Event Status":
            mode === "edit"
                ? (
                    <FormControl style={{ marginTop: "-8px" }}>
                        <Select
                            name="eventStatus"
                            value={ev.eventStatus}
                            onChange={handleChange}
                            style={{ width: "150px" }}
                        >
                            {Object.keys(eventStatuses)
                                .map((status) => (
                                    <MenuItem
                                        key={status}
                                        value={status}
                                    >
                                        {eventStatuses[status]}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )
                : eventStatuses[ev.eventStatus],        
    }

    return (
        <Card mb={6}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1} alignItems="center">
                        <Typography variant="h6" gutterBottom>Details</Typography>
                    </Box>
                    <Box>
                        {
                            mode === "view" && (
                                <IconButton onClick={() => setModeField("eventDetails", "edit")}>
                                    <EditIcon />
                                </IconButton>
                            )}
                        {
                            mode === "edit" && (
                                <IconButton onClick={() => setModeField("eventDetails", "view")}>
                                    <UndoIcon />
                                </IconButton>
                            )}
                        {
                            mode === "saving" && (
                                <CircularProgress
                                    size={21}
                                    style={{ marginLeft: "9px" }}
                                />
                            )}
                    </Box>
                </Box>
                {
                    Object.keys(eventDetails).map((key, index) => (
                        <Grid key={index} container className={classes.gridContainer}>
                            <Grid item xs={6} md={12} lg={5} className={classes.gridItem}>
                                <Typography variant="body2">{key}</Typography>
                            </Grid>
                            <Grid item xs={6} md={12} lg={7} className={classes.gridItem}>
                                <Typography variant="subtitle2">{eventDetails[key]}</Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            </CardContent>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeEventDetailsCard);
