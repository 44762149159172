import React, { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { statuses } from "./dynamicsConstants";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import axios from "../../plugins/axios";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    IconButton
} from "@material-ui/core";
import {
    Delete as DeleteIcon
} from "@material-ui/icons";


const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const KnowledgeDocumentsTable = ({ documentIds, knowledgeState, allColumns, getInitialData, onRowClick, onDelete }) => {
    const [page, setPage] = useState(0);

    const { categories, documents, filterText } = knowledgeState;
    const rowsPerPage = 10;

    const history = useHistory();

    const activeFilter = useMemo(() => {
        if (filterText.length < 3)
            return "";

        setPage(0);

        return filterText.toLowerCase();
    }, [filterText]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {

        if (categories.length === 0)
            getInitialData();
    }, [categories.length, getInitialData]);

    const visibleDocuments = useMemo(() => {
        var list = documents;
        if (documentIds)
            list = documents.filter(d => documentIds.includes(d.id));

        if (allColumns && activeFilter !== "")
            list = list.filter(d => d.name?.toLowerCase().includes(activeFilter));
        
        return list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }, [documents, documentIds, allColumns, activeFilter, page]);

    const documentCount = useMemo(() => { return documentIds ? documentIds.length : documents.length }, [documents, documentIds]);

    const documentSelected = (id) => {
        if (onRowClick)
            onRowClick(id)
        else
            history.push(`/admin/knowledge/document/${id}`);
            //downloadDocument(doc);
    };

    const downloadDocument = async (doc) => {
        var response = await axios.get(
            `${apiUrl}/document/retrieve/${doc.identifier}`,
            { responseType: "arraybuffer" }
        );
        console.log(response);
        let blob = new Blob([response.data], { type: doc.contentType });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = doc.filename;
        link.click();
    };

    return (
        <React.Fragment>
            <Table size="small" style={{tableLayout:'fixed', width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell style={{width:'20%'}}>Created On</TableCell>
                        {allColumns && <TableCell>Status</TableCell>}
                        {allColumns && <TableCell>Next Review Date</TableCell>}
                        {allColumns && <TableCell>Review Date</TableCell>}
                        {onDelete && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        visibleDocuments.map((d) => (
                            <TableRow key={d.id} onClick={() => documentSelected(d.id)} style={{ cursor: 'pointer' }}>
                                <TableCell style={{  whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{d.name}</TableCell>
                                <TableCell>{format(new Date(d.createdOn), "dd/MM/yyyy HH:mm")}</TableCell>
                                {allColumns && <TableCell>{statuses[d.articleStatus]}</TableCell>}
                                {allColumns && <TableCell>{d.nextReviewDate && format(new Date(d.nextReviewDate), "dd/MM/yyyy")}</TableCell>}
                                {allColumns && <TableCell>{d.reviewDate && format(new Date(d.reviewDate), "dd/MM/yyyy")}</TableCell>}
                                {onDelete && <TableCell><IconButton onClick={(e) => { e.stopPropagation(); onDelete(d.id); }}><DeleteIcon /></IconButton></TableCell>}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={documentCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </React.Fragment>
    );
};


const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeDocumentsTable);
