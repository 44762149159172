import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { format } from "date-fns";
import { eventStatuses } from "./dynamicsConstants";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    IconButton
} from "@material-ui/core";
import {
    Delete as DeleteIcon
} from "@material-ui/icons";


const KnowledgeEventsTable = ({ knowledgeState, getInitialData }) => {
    const [page, setPage] = useState(0);

    const history = useHistory();

    const { categories, events, filterText } = knowledgeState;
    const rowsPerPage = 10;

    const activeFilter = useMemo(() => {
        if (filterText.length < 3)
            return "";

        setPage(0);

        return filterText.toLowerCase();
    }, [filterText]);
   

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {

        if (categories.length === 0)
            getInitialData();
    }, [categories.length, getInitialData]);

    const visibleEvents = useMemo(() => {
        var list = events;

        if (activeFilter !== "")
            list = list.filter(e => e.name.toLowerCase().includes(activeFilter));
        
        return list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    }, [activeFilter, events, page]);    
    
    return (
        <React.Fragment>
            <Table size="small" style={{tableLayout:'fixed', width: '100%'}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Date</TableCell>
                        <TableCell style={{width:'20%'}}>Created On</TableCell>
                        <TableCell>Status</TableCell>                        
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        visibleEvents.map((e) => (
                            <TableRow key={e.id} onClick={() => history.push(`/admin/knowledge/event/${e.id}`) } style={{ cursor: 'pointer' }}>
                                <TableCell style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</TableCell>
                                <TableCell>{format(new Date(e.start), "dd/MM/yyyy")}</TableCell>
                                <TableCell>{format(new Date(e.createdOn), "dd/MM/yyyy HH:mm")}</TableCell>
                                <TableCell>{eventStatuses[e.eventStatus]}</TableCell>                                
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={events.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </React.Fragment>
    );
};


const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeEventsTable);
