import React, { useEffect, useState, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { spacing } from "@material-ui/system";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import KnowledgeDocumentDetailsCard from "./KnowledgeDocumentDetailsCard";
import { grey } from "@material-ui/core/colors";
import MyCKEditor from '../../ckeditor/MyCKEditor';

import {
    Typography,
    Card as MuiCard,
    CardContent,
    Grid,
    Button as MuiIconButton,
    CircularProgress,
    Box,
    TextField
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Replay as UndoIcon,
    Save as SaveIcon
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const KnowledgeDocument = ({ getInitialData, knowledgeState, updateDocument, setModeField }) => {
    const { categories, documents, modeFields } = knowledgeState;
    const { documentId } = useParams();
     
    const [name, setName] = useState();    

    const { eventName: nameMode, eventEventDetails: eventDetailsMode } = modeFields; 

    const document = useMemo(() => documents?.find(a => a.id === parseInt(documentId)), [documents, documentId]);

    const handleEventDetailsSave = async () => {
        let eventDetails = editor.getData();

        let newEvent = { ...document, eventDetails: eventDetails };
        updateDocument(newEvent, "eventDetails", "eventEventDetails");
    };

    const handleNameSave = async () => {
        let newEvent = { ...document, name };
        updateDocument(newEvent, "name", "eventName");
    }

    const handleNameReset = () => {
        setName(document.name);
        setModeField("eventName", "view");
    }

    const [editor, setEditor] = useState();

    useEffect(() => {

        if (categories.length === 0)
            getInitialData();        

    }, [categories, getInitialData, document]);

    const editName = () => {
        setName(document.name);
        setModeField("eventName", "edit");
    } 

    if (!document)
        return <Loader />;

    return (
        <React.Fragment>
            <Helmet title="Knowledge Base" />
            <Card mb={6}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>{document.name}</Typography>
                </CardContent>
            </Card>
            <Grid justifyContent="space-between" container spacing={6}>
                <Grid item xs={5}>
                    <KnowledgeDocumentDetailsCard document={document} />                    
                </Grid>
                <Grid item xs={7}>
                    <Card mb={6}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Name</Typography>
                                </Box>
                                <Box>
                                    {
                                        nameMode === "view" && (
                                            <IconButton onClick={editName}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    {
                                        nameMode === "edit" && (
                                            <React.Fragment>
                                                <IconButton onClick={handleNameReset}><UndoIcon /></IconButton>
                                                <IconButton onClick={handleNameSave}><SaveIcon /></IconButton>
                                            </React.Fragment>
                                        )}
                                    {
                                        nameMode === "saving" && (
                                            <CircularProgress
                                                size={21}
                                                style={{ marginLeft: "9px" }}
                                            />
                                        )}
                                </Box>
                            </Box>
                            {
                                nameMode === "edit"
                                    ? <TextField
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                    : <div>{document.name}</div>
                            }
                        </CardContent>
                    </Card>
                    <Card mb={7}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Event Details</Typography>
                                </Box>
                                <Box>
                                    {
                                        eventDetailsMode === "view" && (
                                            <IconButton onClick={() => setModeField("eventEventDetails", "edit")}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    {
                                        eventDetailsMode === "edit" && (
                                            <React.Fragment>
                                                <IconButton onClick={() => setModeField("eventEventDetails", "view")}><UndoIcon /></IconButton>
                                                <IconButton onClick={handleEventDetailsSave}><SaveIcon /></IconButton>
                                            </React.Fragment>
                                        )}
                                    {
                                        eventDetailsMode === "saving" && (
                                            <CircularProgress
                                                size={21}
                                                style={{ marginLeft: "9px" }}
                                            />
                                        )}
                                </Box>
                            </Box>
                            {
                                eventDetailsMode === "edit"
                                    ? <MyCKEditor
                                        initialData={document.eventDetails}
                                        setEditor={setEditor}
                                        onChange={() => { }}
                                        onFocus={() => { }}
                                        type="article"
                                    />
                                    : <div dangerouslySetInnerHTML={{ __html: document.eventDetails }} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeDocument);
