import { useState, useMemo, useEffect } from "react";
import { connect } from "react-redux";
import { format } from "date-fns";
import { statuses } from "./dynamicsConstants";
import { useHistory } from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TablePagination,
    IconButton
} from "@material-ui/core";
import {
    Delete as DeleteIcon   
} from "@material-ui/icons";

const KnowledgeArticlesTable = ({ articleIds, knowledgeState, allColumns, getInitialData, onRowClick, withoutArticleIds, onDelete }) => {
    const history = useHistory();
    const [page, setPage] = useState(0);

    const { categories, articles, filterText } = knowledgeState;
    const rowsPerPage = 10;

    const activeFilter = useMemo(() => {
        if (filterText.length < 3)
            return "";

        setPage(0);

        return filterText.toLowerCase();
    }, [filterText]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {

        if (categories.length === 0)
            getInitialData();
    }, [categories.length, getInitialData]);

    const allowedArticles = useMemo(() => {
        var list = articles;
        if (articleIds)
            list = articles.filter(a => articleIds.includes(a.id));

        if (withoutArticleIds)
            list = articles.filter(a => !withoutArticleIds.includes(a.id));

        if (allColumns && activeFilter !== "")
            list = list.filter(a => a.name.toLowerCase().includes(activeFilter));
        
        return list;
    }, [articles, articleIds, withoutArticleIds, allColumns, activeFilter]);

    const visibleArticles = useMemo(() => {
        return allowedArticles.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [allowedArticles, page]);
    
    const articleSelected = id => {
        if (onRowClick)
            onRowClick(id)
        else
            history.push(`/admin/knowledge/article/${id}`);
    }

    return (
        <>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Created On</TableCell>
                        {allColumns && <TableCell>Modified On</TableCell>}
                        {allColumns && <TableCell>Next Review Date</TableCell>}
                        {allColumns && <TableCell>Review Date</TableCell>}
                        {allColumns && <TableCell>Status</TableCell>}
                        <TableCell>Sub Category</TableCell>
                        {onDelete && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        visibleArticles.map((a) => (
                            <TableRow key={a.id} onClick={() => articleSelected(a.id) } style={{ cursor: 'pointer' }}>
                                <TableCell>{a.name}</TableCell>
                                <TableCell>{format(new Date(a.createdOn), "dd/MM/yyyy HH:mm")}</TableCell>
                                {allColumns && <TableCell>{format(new Date(a.modifiedOn), "dd/MM/yyyy HH:mm")}</TableCell>}
                                {allColumns && <TableCell>{a.nextReviewDate && format(new Date(a.nextReviewDate), "dd/MM/yyyy")}</TableCell>}
                                {allColumns && <TableCell>{a.reviewDate && format(new Date(a.reviewDate), "dd/MM/yyyy")}</TableCell>}
                                {allColumns && <TableCell>{statuses[a.articleStatus]}</TableCell>}
                                <TableCell>{categories[a.categoryId]}</TableCell>
                                {onDelete && <TableCell><IconButton onClick={(e) => { e.stopPropagation();  onDelete(a.id); } }><DeleteIcon /></IconButton></TableCell>}
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
            <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={allowedArticles.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </>
    );
};


const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeArticlesTable);
