import { connect } from "react-redux";
import styled from "styled-components";
import Loader from "../Loader";
import { format } from "date-fns";
import { spacing } from "@material-ui/system";
import { primaryCategories, statuses } from "./dynamicsConstants";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import { grey } from "@material-ui/core/colors";
import { KeyboardDatePicker } from "@material-ui/pickers";


import {
    Typography,
    Card as MuiCard,
    CardContent,
    Grid,
    Button as MuiIconButton,
    makeStyles,
    CircularProgress,
    Box,
    FormControl,
    MenuItem,
    Select
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Replay as UndoIcon
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const useStyles = makeStyles((theme) => ({
    gridContainer: {
        margin: theme.spacing(4, 4),
    },
    gridItem: {
        paddingRight: theme.spacing(4),
    },
}));

const KnowledgeDocumentDetailsCard = ({ knowledgeState, updateDocument, document, setModeField }) => {
    const { categories, modeFields } = knowledgeState;

    const classes = useStyles();
    const mode = modeFields.articleDetails;

    const handleChange = async (e) => {

        let field = e.target.name;
        let value = e.target.value;

        let newArticle = { ...document, [field]: value }
        updateDocument(newArticle, field, "articleDetails");
    };

    const handleDateChange = async (field, date) => {
        let newArticle = { ...document, [field]: date }
        updateDocument(newArticle, field, "articleDetails");        
    };

    const articleReviewDate = document.reviewDate ? new Date(document.reviewDate) : null;
    const articleNextReviewDate = document.nextReviewDate ? new Date(document.nextReviewDate) : null;
    
    if (!document)
        return <Loader />;

    const articleDetails = {
        "Review Date": mode === "edit"
            ? (
                <KeyboardDatePicker
                    style={{ marginTop: "-8px" }}
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={articleReviewDate}
                    onChange={(date) => handleDateChange('reviewDate', date)}
                />
            )
            : document.reviewDate && format(articleReviewDate, "dd/MM/yyyy"),
        "Next Review Date": mode === "edit"
            ? (
                <KeyboardDatePicker
                    style={{ marginTop: "-8px" }}
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={articleNextReviewDate}
                    onChange={(date) => handleDateChange('nextReviewDate', date)}
                />
            )
            : document.nextReviewDate && format(articleNextReviewDate, "dd/MM/yyyy"),
        "Article Status":
            mode === "edit"
                ? (
                    <FormControl style={{ marginTop: "-8px" }}>
                        <Select
                            name="articleStatus"
                            value={document.articleStatus}
                            onChange={handleChange}
                            style={{ width: "150px" }}
                        >
                            {Object.keys(statuses)
                                .map((status) => (
                                    <MenuItem
                                        key={status}
                                        value={status}
                                    >
                                        {statuses[status]}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )
                : statuses[document.articleStatus],
        "Primary Category":
            mode === "edit"
                ? (
                    <FormControl style={{ marginTop: "-8px" }}>
                        <Select
                            name="primaryCategory"
                            value={document.primaryCategory}
                            onChange={handleChange}
                            style={{ width: "150px" }}
                        >
                            {Object.keys(primaryCategories)
                                .map((categoryId) => (
                                    <MenuItem
                                        key={categoryId}
                                        value={categoryId}
                                    >
                                        {primaryCategories[categoryId]}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )
                : primaryCategories[document.primaryCategory],
        "Sub Category":
            mode === "edit"
                ? (
                    <FormControl style={{ marginTop: "-8px" }}>
                        <Select
                            name="categoryId"
                            value={document.categoryId}
                            onChange={handleChange}
                            style={{ width: "150px" }}
                        >
                            {Object.keys(categories)
                                .map((categoryId) => (
                                    <MenuItem
                                        key={categoryId}
                                        value={categoryId}
                                    >
                                        {categories[categoryId]}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )
                : categories[document.categoryId]
    }

    return (
        <Card mb={6}>
            <CardContent>
                <Box display="flex">
                    <Box flexGrow={1} alignItems="center">
                        <Typography variant="h6" gutterBottom>Details</Typography>
                    </Box>
                    <Box>
                        {
                            mode === "view" && (
                                <IconButton onClick={() => setModeField("articleDetails", "edit")}>
                                    <EditIcon />
                                </IconButton>
                            )}
                        {
                            mode === "edit" && (
                                <IconButton onClick={() => setModeField("articleDetails", "view")}>
                                    <UndoIcon />
                                </IconButton>
                            )}
                        {
                            mode === "saving" && (
                                <CircularProgress
                                    size={21}
                                    style={{ marginLeft: "9px" }}
                                />
                            )}
                    </Box>
                </Box>
                {
                    Object.keys(articleDetails).map((key, index) => (
                        <Grid key={index} container className={classes.gridContainer}>
                            <Grid item xs={6} md={12} lg={5} className={classes.gridItem}>
                                <Typography variant="body2">{key}</Typography>
                            </Grid>
                            <Grid item xs={6} md={12} lg={7} className={classes.gridItem}>
                                <Typography variant="subtitle2">{articleDetails[key]}</Typography>
                            </Grid>
                        </Grid>
                    ))
                }
            </CardContent>
        </Card>
    );
};

const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeDocumentDetailsCard);
