import * as types from '../constants';
import axios from "../../plugins/axios";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const initialState = {
    currentTab: "articles",
    categories: [],
    articles: [],
    documents: [],
    events: [],
    currentArticle: {},
    modeFields: {},
    filterText: ""
};

export const actionCreators = {
    getInitialData: () => async (dispatch, getState) => {
        let response = await axios.get(`${apiUrl}/knowledge/categories`);
        dispatch({ type: types.SET_KNOWLEDGE_CATEGORIES, categories: response.data });

        response = await axios.get(`${apiUrl}/knowledge/articles`);
        dispatch({ type: types.SET_KNOWLEDGE_ARTICLES, articles: response.data });

        console.log({articles: response.data});

        response = await axios.get(`${apiUrl}/knowledge/documents`);
        dispatch({ type: types.SET_KNOWLEDGE_DOCUMENTS, documents: response.data });

        response = await axios.get(`${apiUrl}/knowledge/events`);
        dispatch({ type: types.SET_KNOWLEDGE_EVENTS, events: response.data });
    },

    setModeField: (field, mode) => (dispatch) => {
        dispatch({ type: types.UPDATE_KNOWLEDGE_MODE, field, mode });
    },
    
    getArticle: (articleId) => async (dispatch) => {
        let response = await axios.get(`${apiUrl}/knowledge/article/${articleId}`);
        let article = response.data;       

        dispatch({ type: types.UPDATE_ARTICLE, article });
    },

    updateArticle: (article, field, modeField) => async (dispatch) => {
        dispatch({ type: types.UPDATE_KNOWLEDGE_MODE, field: modeField, mode: "saving" });
        await axios.post(`${apiUrl}/knowledge/updatearticle`, { id: article.id, [field]: article[field] });
        dispatch({ type: types.UPDATE_ARTICLE, article });
        dispatch({ type: types.UPDATE_KNOWLEDGE_MODE, field: modeField, mode: "view" });
    },

    getEvent: (ev) => async (dispatch) => {
        let response = await axios.get(`${apiUrl}/knowledge/event/${ev.id}`);      

        console.log({ ...ev });
        ev = {
            ...ev,
            ...response.data
        }

        dispatch({ type: types.UPDATE_EVENT, ev });
    },

    updateEvent: (ev, field, modeField) => async (dispatch) => {
        dispatch({ type: types.UPDATE_KNOWLEDGE_MODE, field: modeField, mode: "saving" });
        console.log({ id: ev.id, [field]: ev[field] });
        await axios.post(`${apiUrl}/knowledge/updateevent`, { id: ev.id, [field]: ev[field] });
        dispatch({ type: types.UPDATE_EVENT, ev });
        dispatch({ type: types.UPDATE_KNOWLEDGE_MODE, field: modeField, mode: "view" });
    },

    setFilterText: (text) => (dispatch) => {
        dispatch({ type: types.SET_KNOWLEDGE_FILTER, text });
    },

    setCurrentTab: (tab) => (dispatch) => {
        dispatch({ type: types.SET_KNOWLEDGE_TAB, tab });
    }

};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {

        case (types.SET_KNOWLEDGE_CATEGORIES): {
            return {
                ...state,
                categories: action.categories
            };
        }

        case (types.SET_KNOWLEDGE_ARTICLES): {
            return {
                ...state,
                articles: action.articles
            };
        }

        case (types.SET_KNOWLEDGE_DOCUMENTS): {
            return {
                ...state,
                documents: action.documents
            };
        }

        case (types.SET_KNOWLEDGE_EVENTS): {
            return {
                ...state,
                events: action.events
            };
        }

        case (types.UPDATE_ARTICLE): {
            let article = action.article;
            return {
                ...state,
                articles: state.articles.map(x => x.id === article.id ? article : x)
            };
        }

        case (types.UPDATE_EVENT): {
            let ev = action.ev;
            return {
                ...state,
                events: state.events.map(x => x.id === ev.id ? ev : x)
            };
        }

        case (types.UPDATE_KNOWLEDGE_MODE): {
            return {
                ...state,
                modeFields: {
                    ...state.modeFields,
                    [action.field]: action.mode
                }
            };
        }

        case (types.SET_KNOWLEDGE_FILTER): {
            return {
                ...state,
                filterText: action.text
            };
        }

        case (types.SET_KNOWLEDGE_TAB): {
            return {
                ...state,
                currentTab: action.tab
            };
        }
        

        default:
            return state;
    }
};

export default reducer;