import React, { useState } from "react";
import Helmet from "react-helmet";
import styled from "styled-components";
import { spacing } from "@material-ui/system";
import KnowledgeArticlesTable from "./KnowledgeArticlesTable";
import KnowledgeDocumentsTable from "./KnowledgeDocumentsTable";
import TabPanel from "../TabPanel";
import {
    Tabs,
    Tab,
    Card as MuiCard,
    CardContent,
    Box,
    TextField,
} from "@material-ui/core";
import useFeatureFlag from "../../hooks/useFeatureFlag";
import { Redirect } from "react-router-dom";
import KnowledgeEventsTable from "./KnowledgeEventsTable";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import { connect } from "react-redux";

const Card = styled(MuiCard)(spacing);

const KnowledgeAdmin = ({ knowledgeState, setFilterText, setCurrentTab }) => {    

    const { filterText, currentTab } = knowledgeState;
    const { data: isEnabled } = useFeatureFlag("KnowledgeBank");

    return isEnabled ? (
        <React.Fragment>
            <Helmet title="Knowledge Base" />
            <Box display="flex" alignItems="center" style={{ marginBottom: "10px" }}>
                <Box flexGrow={1}>
                    <Tabs value={currentTab} onChange={(e, x) => setCurrentTab(x)} >
                        <Tab value="articles" label="Articles" />
                        <Tab value="documents" label="Documents" />
                        <Tab value="events" label="Events" />
                    </Tabs>
                </Box>                
                <TextField
                    variant="outlined"
                    size="small"
                    placeholder="filter"
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                />
            </Box>            
            <TabPanel value={currentTab} index="articles">
                <Card mb={6}>
                    <CardContent>
                        <KnowledgeArticlesTable allColumns />
                    </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={currentTab} index="documents">
                <Card mb={6}>
                    <CardContent>
                        <KnowledgeDocumentsTable allColumns/>
                    </CardContent>
                </Card>
            </TabPanel>
            <TabPanel value={currentTab} index="events">
                <Card mb={6}>
                    <CardContent>
                        <KnowledgeEventsTable />
                    </CardContent>
                </Card>
            </TabPanel>
        </React.Fragment>
    ) : isEnabled === false ? (
        <Redirect to="/" />
    ) : (
        <div>Loading...</div>
    );
};

const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeAdmin);
