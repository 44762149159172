import React, { useEffect, useState, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { spacing } from "@material-ui/system";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import KnowledgeEventDetailsCard from "./KnowledgeEventDetailsCard";
import { grey } from "@material-ui/core/colors";
import MyCKEditor from '../../ckeditor/MyCKEditor';

import {
    Typography,
    Card as MuiCard,
    CardContent,
    Grid,
    Button as MuiIconButton,
    CircularProgress,
    Box,
    TextField
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Replay as UndoIcon,
    Save as SaveIcon
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const KnowledgeEvent = ({ getInitialData, knowledgeState, updateEvent, getEvent, setModeField }) => {
    const { categories, events, modeFields } = knowledgeState;
    const { eventId } = useParams();

    const [name, setName] = useState();
    const [registerUrl, setRegisterUrl] = useState();    


    const {
        eventName: nameMode,
        eventEventDetails: eventDetailsMode,
        eventRegisterUrl: registerUrlMode
    } = modeFields;

    useEffect(() => {
        setModeField("eventName", "view");
        setModeField("eventEventDetails", "view");
        setModeField("eventRegisterUrl", "view");
    }, [setModeField]);

    const ev = useMemo(() => events?.find(a => a.id === parseInt(eventId)), [events, eventId]);

    useEffect(() => {

        if (categories.length === 0)
            getInitialData();
        else if (ev && !ev.eventDetails)
            getEvent(ev);

    }, [categories, getInitialData, ev, getEvent]);    

    const handleEventDetailsSave = async () => {
        let eventDetails = editor.getData();

        let newEvent = { ...ev, eventDetails: eventDetails };
        updateEvent(newEvent, "eventDetails", "eventEventDetails");
    };

    const handleNameSave = async () => {
        let newEvent = { ...ev, name };
        updateEvent(newEvent, "name", "eventName");
    }

    const handleRegisterUrlSave = async () => {
        let newEvent = { ...ev, registerUrl };
        updateEvent(newEvent, "registerUrl", "eventRegisterUrl");
    }

    const handleNameReset = () => {
        setName(ev.name);
        setModeField("eventName", "view");
    }    

    const handleRegisterUrlReset = () => {
        setRegisterUrl(ev.registerUrl);
        setModeField("eventRegisterUrl", "view");
    }

    const [editor, setEditor] = useState();
    

    const editName = () => {
        setName(ev.name);
        setModeField("eventName", "edit");
    }

    const editRegisterUrl = () => {
        setRegisterUrl(ev.registerUrl);
        setModeField("eventRegisterUrl", "edit");
    } 

    if (!ev)
        return <Loader />;

    return (
        <React.Fragment>
            <Helmet title="Knowledge Base" />
            <Card mb={6}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>{ev.name}</Typography>
                </CardContent>
            </Card>
            <Grid justifyContent="space-between" container spacing={6}>
                <Grid item xs={5}>
                    <KnowledgeEventDetailsCard ev={ev} />                    
                </Grid>
                <Grid item xs={7}>
                    <Card mb={6}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Name</Typography>
                                </Box>
                                <Box>
                                    {
                                        nameMode === "view" && (
                                            <IconButton onClick={editName}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    {
                                        nameMode === "edit" && (
                                            <React.Fragment>
                                                <IconButton onClick={handleNameReset}><UndoIcon /></IconButton>
                                                <IconButton onClick={handleNameSave}><SaveIcon /></IconButton>
                                            </React.Fragment>
                                        )}
                                    {
                                        nameMode === "saving" && (
                                            <CircularProgress
                                                size={21}
                                                style={{ marginLeft: "9px" }}
                                            />
                                        )}
                                </Box>
                            </Box>
                            {
                                nameMode === "edit"
                                    ? <TextField
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        value={name}
                                        onChange={e => setName(e.target.value)}
                                    />
                                    : <div>{ev.name}</div>
                            }
                        </CardContent>
                    </Card>
                    <Card mb={6}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Register Url</Typography>
                                </Box>
                                <Box>
                                    {
                                        registerUrlMode === "view" && (
                                            <IconButton onClick={editRegisterUrl}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    {
                                        registerUrlMode === "edit" && (
                                            <React.Fragment>
                                                <IconButton onClick={handleRegisterUrlReset}><UndoIcon /></IconButton>
                                                <IconButton onClick={handleRegisterUrlSave}><SaveIcon /></IconButton>
                                            </React.Fragment>
                                        )}
                                    {
                                        registerUrlMode === "saving" && (
                                            <CircularProgress
                                                size={21}
                                                style={{ marginLeft: "9px" }}
                                            />
                                        )}
                                </Box>
                            </Box>
                            {
                                registerUrlMode === "edit"
                                    ? <TextField
                                        style={{ width: '100%' }}
                                        variant="outlined"
                                        value={registerUrl}
                                        onChange={e => setRegisterUrl(e.target.value)}
                                    />
                                    : <div>{ev.registerUrl}</div>
                            }
                        </CardContent>
                    </Card>
                    <Card mb={7}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Event Details</Typography>
                                </Box>
                                <Box>
                                    {
                                        eventDetailsMode === "view" && (
                                            <IconButton onClick={() => setModeField("eventEventDetails", "edit")}>
                                                <EditIcon />
                                            </IconButton>
                                        )}
                                    {
                                        eventDetailsMode === "edit" && (
                                            <React.Fragment>
                                                <IconButton onClick={() => setModeField("eventEventDetails", "view")}><UndoIcon /></IconButton>
                                                <IconButton onClick={handleEventDetailsSave}><SaveIcon /></IconButton>
                                            </React.Fragment>
                                        )}
                                    {
                                        eventDetailsMode === "saving" && (
                                            <CircularProgress
                                                size={21}
                                                style={{ marginLeft: "9px" }}
                                            />
                                        )}
                                </Box>
                            </Box>
                            {
                                eventDetailsMode === "edit"
                                    ? <MyCKEditor
                                        initialData={ev.eventDetails}
                                        setEditor={setEditor}
                                        onChange={() => { }}
                                        onFocus={() => { }}
                                        type="article"
                                    />
                                    : <div dangerouslySetInnerHTML={{ __html: ev.eventDetails }} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeEvent);
