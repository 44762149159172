import React, { useState, useEffect, useRef } from 'react';
import {
    Button as MuiIconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import useInterval from '../hooks/useInterval';
import { setUser } from '../redux/actions/userActions';
import {useAuth} from "../contexts/authContext";

const oneMinuteInterval = 60000;
const expiryCutoff = 30;

const TokenCheckerDialog = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const [checkToken, setCheckToken] = useState(true);
    const [countdown, setCountdown] = useState(0);
    const { user, initialiseAzureSession } = useAuth();
    const countdownTimerRef = useRef();

    useInterval({
        callback: () => {
            if (!checkToken || !user)
                return;
            
            const minsUntilExpiry = moment(user.expiresOn).diff(moment(), 'minutes');
    
            if (minsUntilExpiry < expiryCutoff) {
                setCountdown(minsUntilExpiry);
                setCheckToken(false);
                setOpen(true);
                startCountdownTimer();
            }
        }, 
        delay: oneMinuteInterval
    });

    useEffect(() => {
        if (open && countdown <= 1) {
            stopCountdownTimer();
            setOpen(false);
            dispatch(setUser(null));
        }
    }, [countdown, dispatch, open]);

    const startCountdownTimer = () => {
        countdownTimerRef.current = setInterval(() => {
            setCountdown(current => current - 1);
        }, oneMinuteInterval);
    };

    const stopCountdownTimer = () => {
        clearInterval(countdownTimerRef.current);
    };

    const handleGetNewToken = async () => {
        initialiseAzureSession()
        setOpen(false);
        stopCountdownTimer();
        setCountdown(0);
        setCheckToken(true);
    };

    const handleCloseDialog = (event, reason) => {
        if (reason !== 'backdropClick') {
            setOpen(false);
        }        
    };

    return (
        <Dialog
            open={open}
            onClose={handleCloseDialog}
            aria-labelledby='token-checker-dialog-title'
            aria-describedby='token-checker-dialog-description'>
            <DialogTitle id='token-checker-dialog-title'>
                Session Inactive
            </DialogTitle>
            <DialogContent>
                <DialogContentText id='token-checker-dialog-description'>
                    Please confirm that you wish to continue with the current
                    session by clicking 'Continue Session' below (note that no
                    unsaved data will be lost).
                </DialogContentText>
                <DialogContentText id='token-checker-dialog-description'>
                    Time remaining in current session is {countdown} minutes
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <MuiIconButton onClick={handleGetNewToken} color='primary'>
                    Continue Session
                </MuiIconButton>
            </DialogActions>
        </Dialog>
    );
};

export default TokenCheckerDialog;
