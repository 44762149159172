import React, { useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import caseActivityTypes from "../constants/caseActivityTypes";
import NotePreviewDialog from "./dialogs/NotePreviewDialog";
import { setFullScreenEmail } from "../redux/actions/userActions";
import useEmailByCaseActivityId from "../hooks/queries/useEmailByCaseActivityId";
import CaseActivityMenu from "./CaseActivityMenu";
import {
    removeCaseHistoryActivity,
    removeCaseHistoryUser,
    resetCaseHistoryFilters,
    setCaseHistoryActivity,
    setCaseHistoryChargeable,
    setCaseHistoryDateFrom,
    setCaseHistoryDateTo,
    removeCaseHistoryChargeable,
    removeCaseHistoryDirection,
    setCaseHistoryDirection,
    setCaseHistoryUser,
} from "../redux/actions/caseActions";
import CaseHistoryComponent from "./CaseHistoryComponent";
import useCaseActivities from "../hooks/queries/useCaseActivities";
import { caseChargeableStatus } from "../constants/caseChargeableStatus";

const useStyles = makeStyles(() => ({
    root: {
        display: "flex",
    },
    number: {
        fontWeight: 600,
    },
}));

const isActivityType = (activities = {}, activityType) => {
    if (Object.keys(activities).length === 0) return false;

    return activities.hasOwnProperty(activityType);
};

const getActivityTypeIds = (activities = {}, activityType) => {
    const listOfActivities = Object.values(activities);

    if (listOfActivities.length === 0) return [];
    let ids = [];
    if (activityType === caseActivityTypes.MISC) {
        listOfActivities.forEach((activity) => {
            if (activity.activityType === activityType)
                ids.push(activity.activityTypeId);
        });
        return ids;
    }

    const activityTypes = listOfActivities.filter(
        (activity) =>
            activity.activityType !== caseActivityTypes.ACTION &&
            activity.activityType !== caseActivityTypes.OUTCOME &&
            activity.activityType !== caseActivityTypes.MISC
    );

    activityTypes.forEach((activity) => ids.push(activity.activityTypeId));
    return ids;
};

const getChargeableTypes = (filters = []) => {
    if (filters.length <= 0) return [];

    return Object.keys(filters).map((filter) => {
        if (filter === caseChargeableStatus.CHARGEABLE.label)
            return caseChargeableStatus.CHARGEABLE.isChargeable;

        return caseChargeableStatus.NON_CHARGEABLE.isChargeable;
    });
};

const { NOTE, MISC, INTERNAL_NOTE, EMAIL, CALL_WITH_NOTE, CHAT_WITH_NOTE } = caseActivityTypes;

const valuesPerPage = 10;

const CaseHistory = ({ caseId, openDocument, onReply, onForward }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(false);
    const [caseActivity, setCaseActivity] = useState(null);
    const [emailActivity, setEmailActivity] = useState(null);

    const [contextAnchorEl, setContextAnchorEl] = useState(null);
    const [contextEvent, setContextEvent] = useState(null);
    const [caseListOpen, setCaseListOpen] = useState(false);

    const [pageNumber, setPageNumber] = useState(1);

    const { users: internalUsers } = useSelector((state) => state.userReducer);
    const { miscActivityTypes } = useSelector((state) => state.configReducer);
    const { cases } = useSelector((state) => state.caseReducer);
    const c = cases[caseId];
    const history = c.historyFilters;

    const { data: caseActivitiesData, isLoading } = useCaseActivities({
        caseId,
        valuesPerPage,
        pageNumber,
        dateFrom: history?.dateFrom,
        dateTo: history?.dateTo,
        activityTypes: getActivityTypeIds(history?.activities, null),
        miscActivityTypes: getActivityTypeIds(
            history?.activities,
            caseActivityTypes.MISC
        ),
        isAction: isActivityType(history?.activities, caseActivityTypes.ACTION),
        isOutcome: isActivityType(
            history?.activities,
            caseActivityTypes.OUTCOME
        ),
        chargeables: getChargeableTypes(history?.chargeables),
        directions: Object.keys(history?.directions) ?? [],
        userIds: Object.keys(history?.users) ?? [],
    });

    const { caseActivities, caseActivitiesCount, caseHistoryFilter } =
        caseActivitiesData?.data || {};

    useEmailByCaseActivityId({
        caseActivityId: emailActivity?.caseActivityId,
        onSuccess: (email) => {
            dispatch(
                setFullScreenEmail(
                    {
                        ...email.data,
                        ...emailActivity,
                        content: email.data.content,
                        person: email.data.person,
                    },
                    true
                )
            );
            setEmailActivity(null);
        },
    });

    const numberOfPages = useMemo(() => {
        return Math.ceil(caseActivitiesCount / valuesPerPage);
    }, [caseActivitiesCount]);

    const handlePageChange = (event, value) => {
        setPageNumber(value);
    };

    const handleOpenContextMenu = (e, event) => {
        setContextEvent(e);
        setContextAnchorEl(event.currentTarget);
    };

    const handleFullScreenOpen = (e) => {
        if (e.itemType === NOTE || e.itemType === INTERNAL_NOTE || e.itemType === MISC || e.itemType === CALL_WITH_NOTE || e.itemType === CHAT_WITH_NOTE) {
            setCaseActivity(e);
            setOpen(true);
        }

        if (e.itemType === EMAIL) {
            setEmailActivity(e);
        }
    };

    const handleFullScreenNoteClose = () => {
        setOpen(false);
        setCaseActivity(null);
    };

    const handleCloseContextMenu = () => {
        setContextAnchorEl(null);
        setContextEvent(null);
        setCaseListOpen(false);
    };

    const handleClearAllClick = () => {
        setPageNumber(1);
        dispatch(resetCaseHistoryFilters(caseId));
    };

    const handleSetDateFrom = (date) => {
        const dateStartOfDay = new Date(date.setHours(0, 0, 0, 0));
        setPageNumber(1);
        dispatch(setCaseHistoryDateFrom(caseId, dateStartOfDay.toISOString()));
    };

    const handleSetDateTo = (date) => {
        const dateEndOfDay = new Date(date.setHours(23, 59, 59, 999));
        setPageNumber(1);
        dispatch(setCaseHistoryDateTo(caseId, dateEndOfDay.toISOString()));
    };

    const handleChargeableClick = (chargeableStatus) => {
        setPageNumber(1);
        const { isChargeable, label } = chargeableStatus || {};

        if (history?.chargeables?.hasOwnProperty(label)) {
            return dispatch(removeCaseHistoryChargeable(caseId, label));
        }
        dispatch(setCaseHistoryChargeable(caseId, isChargeable, label));
    };

    const handleDirectionClick = (directionFilter) => {
        setPageNumber(1);
        const { direction, label } = directionFilter || {};
        if (history?.directions?.hasOwnProperty(direction)) {
            return dispatch(removeCaseHistoryDirection(caseId, direction));
        }
        dispatch(setCaseHistoryDirection(caseId, direction, label));
    };

    const handleUserClick = (user) => {
        setPageNumber(1);
        const { userExternalId, label } = user || {};
        if (history?.users?.hasOwnProperty(userExternalId)) {
            return dispatch(removeCaseHistoryUser(caseId, userExternalId));
        }
        dispatch(setCaseHistoryUser(caseId, userExternalId, label));
    };

    const handleActivityClick = (activity) => {
        setPageNumber(1);
        if (history?.activities?.hasOwnProperty(activity.name)) {
            return dispatch(removeCaseHistoryActivity(caseId, activity.name));
        }

        return dispatch(
            setCaseHistoryActivity(caseId, activity.name, activity)
        );
    };

    return (
        <div className={classes.root}>
            <CaseHistoryComponent
                caseId={caseId}
                items={caseActivitiesCount}
                filters={caseHistoryFilter}
                activitiesChecked={history?.activities}
                dateFrom={history?.dateFrom}
                dateTo={history?.dateTo}
                isLoading={isLoading}
                internalUsers={internalUsers}
                miscActivityTypes={miscActivityTypes}
                pagedResults={caseActivities}
                valuesPerPage={valuesPerPage}
                pageNumber={pageNumber}
                numberOfPages={numberOfPages}
                onPageChange={handlePageChange}
                onClearAllClick={handleClearAllClick}
                onFromDateChange={handleSetDateFrom}
                onToDateChange={handleSetDateTo}
                onActivityClick={handleActivityClick}
                handleOpenContextMenu={handleOpenContextMenu}
                handleFullScreenOpen={handleFullScreenOpen}
                chargeables={history?.chargeables}
                onChargeableClick={handleChargeableClick}
                directions={history?.directions}
                onDirectionClick={handleDirectionClick}
                selectedUsers={history?.users}
                onUserClick={handleUserClick}
                isUnitisedTime={c?.caseSummary?.isUnitisedTime}
            />
            <CaseActivityMenu
                caseId={caseId}
                contextEvent={contextEvent}
                contextAnchorEl={contextAnchorEl}
                caseListOpen={caseListOpen}
                handleCaseListOpen={setCaseListOpen}
                handleCloseContextMenu={handleCloseContextMenu}
                onReply={onReply}
                onForward={onForward}
            />
            <NotePreviewDialog
                open={open}
                caseActivity={caseActivity}
                handleFullScreenNoteClose={handleFullScreenNoteClose}
                openDocument={openDocument}
            />
        </div>
    );
};

export default CaseHistory;
