import React, { useEffect, useState, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { spacing } from "@material-ui/system";
import { bindActionCreators } from "redux";
import { actionCreators } from "../../redux/reducers/knowledgeReducer";
import LinkedDocumentsCard from "./LinkedDocumentsCard";
import LinkedArticlesCard from "./LinkedArticlesCard";
import KnowledgeArticleDetailsCard from "./KnowledgeArticleDetailsCard";
import { grey } from "@material-ui/core/colors";
import MyCKEditor from '../../ckeditor/MyCKEditor';

import {
    Typography,
    Card as MuiCard,
    CardContent,
    Grid,
    Button as MuiIconButton,
    CircularProgress,
    Box,
    TextField
} from "@material-ui/core";
import {
    Edit as EditIcon,
    Replay as UndoIcon,
    Save as SaveIcon
} from "@material-ui/icons";

const IconButton = styled(MuiIconButton)`
    padding: 2px;
    margin-top: -2px;
    margin-left: 2px;
    min-width: 0;
    color: ${grey[700]};
`;

const Card = styled(MuiCard)(spacing);

const KnowledgeArticle = ({ getInitialData, knowledgeState, updateArticle, getArticle, setModeField }) => {
    const { categories, articles, modeFields } = knowledgeState;
    const { articleId } = useParams();

    const [summary, setSummary] = useState();
     
    const { articleSummary: summaryMode, articleBody: articleMode } = modeFields;

    useEffect(() => {
        setModeField("articleSummary", "view");
        setModeField("articleBody", "view");
    }, [setModeField]);

    const article = useMemo(() => articles?.find(a => a.id === parseInt(articleId)), [articles, articleId]);
        
    const handleArticleSave = async () => {
        let body = editor.getData();  
             
        let newArticle = { ...article, body };
        updateArticle(newArticle, "body", "articleBody");       
    };

    const handleSummarySave = async () => {        
        let newArticle = { ...article, summary };
        updateArticle(newArticle, "summary", "articleSummary");        
    }

    const handleSummaryReset = () => {
        setSummary(article.summary);
        setModeField("articleSummary", "view");
    }    

    const [editor, setEditor] = useState();

    useEffect(() => {       

        if (categories.length === 0)
            getInitialData();
        else if(article && !article.body)
            getArticle(articleId);

    }, [categories, articleId, getInitialData, article, getArticle]);       

    const editSummary = () => {
        setSummary(article.summary);
        setModeField("articleSummary", "edit");        
    }

    if (!article)
        return <Loader />;   

    return (
        <React.Fragment>
            <Helmet title="Knowledge Base" />
            <Card mb={6}>
                <CardContent>
                    <Typography variant="h3" gutterBottom>{article.name}</Typography>
                </CardContent>
            </Card>
            <Grid justifyContent="space-between" container spacing={6}>
                <Grid item xs={5}>
                    <KnowledgeArticleDetailsCard article={article} />
                    {article.childArticleIds && <LinkedArticlesCard article={article} setArticle={updateArticle} />}
                    {article.childDocumentIds && <LinkedDocumentsCard article={article} setArticle={updateArticle} />}             
                </Grid>
                <Grid item xs={7}>
                    <Card mb={6}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Summary</Typography>
                                </Box>
                                <Box>
                                    {
                                        summaryMode === "view" && (
                                        <IconButton onClick={editSummary}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                    {
                                        summaryMode === "edit" && (
                                        <React.Fragment>
                                            <IconButton onClick={handleSummaryReset}><UndoIcon /></IconButton>
                                            <IconButton onClick={handleSummarySave}><SaveIcon /></IconButton>
                                        </React.Fragment>
                                    )}
                                    {
                                        summaryMode === "saving" && (
                                        <CircularProgress
                                            size={21}
                                            style={{ marginLeft: "9px" }}
                                        />
                                    )}
                                </Box>
                            </Box>
                            {
                                summaryMode === "edit"
                                    ? <TextField
                                        style={{ width: '100%' }}
                                        multiline
                                        minRows={8}
                                        variant="outlined"
                                        value={summary}
                                        onChange={e => setSummary(e.target.value)}
                                    />
                                    : <div> {article.summary}</div>
                            }
                        </CardContent>
                    </Card>
                    <Card mb={7}>
                        <CardContent>
                            <Box display="flex">
                                <Box flexGrow={1} alignItems="center">
                                    <Typography variant="h6" gutterBottom>Article</Typography>
                                </Box>
                                <Box>
                                    {
                                        articleMode === "view" && (
                                            <IconButton onClick={() => setModeField("articleBody", "edit")}>
                                            <EditIcon />
                                        </IconButton>
                                    )}
                                    {
                                        articleMode === "edit" && (
                                        <React.Fragment>
                                                <IconButton onClick={() => setModeField("articleBody", "view")}><UndoIcon /></IconButton>
                                            <IconButton onClick={handleArticleSave}><SaveIcon /></IconButton>
                                        </React.Fragment>
                                    )}
                                    {
                                        articleMode === "saving" && (
                                        <CircularProgress
                                            size={21}
                                            style={{ marginLeft: "9px" }}
                                        />
                                    )}
                                </Box>
                            </Box>
                            {
                                articleMode === "edit"
                                    ? <MyCKEditor
                                        initialData={article.body}
                                        setEditor={setEditor}
                                        onChange={() => { }}
                                        onFocus={() => { }}
                                        type="article"
                                    />
                                    : <div dangerouslySetInnerHTML={{ __html: article.body }} />
                            }
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

const mapStateToProps = (state) => ({
    knowledgeState: state.knowledgeReducer
});

const mapDispatchToProps = (dispatch) => bindActionCreators(actionCreators, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(KnowledgeArticle);
